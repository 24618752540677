import React from "react";

import "./styles.scss";

const QuickSummaryContainer = () => {
  return (
    <>
      <div className="quick-summary-container">
        <div className="quickSummaryCard">
          <h4>$1992.60</h4>
          <h5>Average Pay per Week (May 2018)</h5>
          <span>(&#x2B06; 6.2% since May 2016)</span>
        </div>
        <div className="quickSummaryCard">
          <h4>146,800</h4>
          <h5>Estimated Workers by May 2023</h5>
          <span>(&#x2B06; 255,000 workers (21%) from May 2018)</span>
        </div>
        <div className="quickSummaryCard">
          <h4>37 years</h4>
          <h5>Average Age workers</h5>
          <span className="data-neutral">All Jobs Average: 40 years</span>
        </div>
      </div>
      <div className="sources">
        <span>Source(s):</span>
        <ul>
          <li>
            Source: ABS Survey of Employee Earnings and Hours, Australia (cat
            6306.0, May 2016)
          </li>
          <li>
            Source: ABS Survey of Employee Earnings and Hours, Australia (cat
            6306.0, May 2018)
          </li>
          <li>
            Source: 2018 Employment Projections - for the five years to May 2023
            (Labour Market Information Portal)
          </li>
        </ul>
      </div>
    </>
  );
};

export default QuickSummaryContainer;
