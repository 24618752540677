import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

import "./styles.scss";

const OpportunitiesAndRolesContainer = () => {
  const data = [
    { year: 2015, workers: 95600 },
    { year: 2016, workers: 98900 },
    { year: 2017, workers: 105900 },
    { year: 2018, workers: 146800 },
    { year: 2019, workers: null },
    { year: 2020, workers: null },
    { year: 2021, workers: null },
    { year: 2022, workers: null },
    { year: 2023, workers: 225000 }
  ];
  return (
    <div
      className="container"
      style={{ backgroundColor: "#fff", color: "black" }}
    >
      <h2>Opportunities</h2>
      <div className="description">
        <div>
          <BarChart width={280} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="workers" fill="#006DAE" />
          </BarChart>
        </div>
        <div>
          <p>
            Overall, the Information Technology Industry is growing at a really
            fast pace, with an additional 146,000 expected to added for Software
            Engineers by May 2023 according to the predicitions by the
            Australian Government (Labour Market Information Portal).
          </p>
          <p>
            The key tech trends for 2019 according to Deloitte Research are the
            following:
          </p>
          <ul>
            <li>Digital experience. Analytics. Cloud</li>
            <li>Artificial Intelligence</li>
            <li>Serverless Technologies</li>
            <li>Human Computing Interaction</li>
            <li>Customer Experience</li>
            <li>DevSecOps</li>
            <li>Digital Transformation</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OpportunitiesAndRolesContainer;
