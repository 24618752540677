import React from "react";

const SkillsContainer = () => {
  return (
    <div className="container">
      <h2>Skills & Challenges faced by Graduates in Software Engineering</h2>
      <h3>Challenges</h3>
      <ul>
        <li>
          <b>Communication</b> - something that a lot of graduates face when
          entering their first job is knowing when to ask questions. In addition
          to English skills and the perception of work emails to explain
          everything. (Begel & Simon, 2008)
        </li>
        <li>
          <b>Collaboration</b> - collaobration across multiple teams in addition
          to taking the initiative, as well negotiating work.
        </li>
        <li>
          <b>Technical</b> - writing good tests for code as another key
          highlight as well as using the right tools to support large-scale
          development (Begel & Simon, 2008)
        </li>
        <li>
          <b>Cognition</b> - struggle to collect, organise and document the
          range of information they nedd to absorb, as well as note taking
          (Begel & Simon, 2008).
        </li>
        <li>
          <b>Orientation</b> - have difficulty orienting themselves in the low
          information environments in their project team, codebase, and
          resources. (Begel & Simon, 2008).
        </li>
      </ul>
      {/**
       * //TODO: Add Progamming Languages, Environments, Technical Platforms, etc.
       */}
    </div>
  );
};

export default SkillsContainer;
