import React from "react";

import "./styles.scss";

const RoleDescriptionContainer = () => {
  return (
    <div className="container">
      <h2>Role Description</h2>
      <p>
        We live in a modern and technological world, where a lot of technology
        requires to be powered by code. Software Engineers build solutions which
        uses this technologies across both software and web
      </p>
      <blockquote>
        Software and Applications Programmers design, develop, test, maintain
        and document program code in accordance with user requirements, and
        system and technical specifications.
        <br />
        <div>
          Source:{" "}
          <a href="https://joboutlook.gov.au/Occupation?search=Career&code=2613">
            Job Outlook
          </a>
        </div>
      </blockquote>
    </div>
  );
};

export default RoleDescriptionContainer;
