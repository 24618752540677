import React from "react";

import "./Hero.scss";

const Hero = ({ scrollToIntro = () => {} }) => {
  return (
    <div className="hero">
      <h2 className="intro">Career Guide for the </h2>
      <h1 style={{ marginTop: 0 }}>Software Engineer field</h1>
      <h2>by Eric Jiang</h2>
      <div onClick={scrollToIntro} className="button">
        Let's Go!
      </div>
    </div>
  );
};

export default Hero;
