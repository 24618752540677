import React, { useRef } from "react";

import Hero from "./components/Hero/Hero";

import EmployersContainer from "./containers/EmployersContainer";
import EntryPathwaysContainer from "./containers/EntryPathwaysContainer";
import QuickSummaryContainer from "./containers/QuickSummaryContainer/QuickSummaryContainer";
import RoleDescriptionContainer from "./containers/RoleDescriptionContainer/RoleDescriptionContainer";
import SkillsContainer from "./containers/SkillsContainer";
import OpportunitiesAndRolesContainer from "./containers/OpportunitiesAndRolesContainer";

import scrollToRef from "./utils/scrollToRef";

import "./App.scss";
import { ParallaxBanner } from "react-scroll-parallax";

function App() {
  const introRef = useRef(null);
  return (
    <div className="main">
      <Hero scrollToIntro={() => scrollToRef(introRef)} />
      <ParallaxBanner
        layers={[
          {
            image:
              "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80",
            amount: 1
          }
        ]}
      />
      <div className="details" ref={introRef}>
        <QuickSummaryContainer />
        <RoleDescriptionContainer />
        <EntryPathwaysContainer />
        <SkillsContainer />
      </div>
      <OpportunitiesAndRolesContainer />
      <EmployersContainer />
      <div className="container">
        <a
          className="button"
          href="https://au.gradconnection.com/graduate-jobs/information-technology/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Find a Role
        </a>
      </div>
      <ParallaxBanner
        layers={[
          {
            image:
              "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
            amount: 1
          }
        ]}
      />
    </div>
  );
}

export default App;
