import React from "react";

const EntryPathwaysContainer = () => {
  return (
    <div className="container">
      <h2>Entry pathways</h2>
      <p>
        There are many pathways in the Information Technology industry,
        depending on the role - no formal education is typically required -
        experience is more valued than. However, there are also many industry
        certifications and qualifications that can be acquired. Entry pathways
        also vary from job to job too. The degree of Software Engineer typically
        recieves is the following
      </p>
      <ul>
        <li>Bachelor of Computer Science</li>
        <li>Bachelor of Software Engineering</li>
      </ul>
    </div>
  );
};

export default EntryPathwaysContainer;
