import React from "react";
import CompanyCard from "../components/CompanyCard";

const EmployersContainer = () => {
  return (
    <div className="container">
      <h2>Key Employers</h2>
      <div>
        <h3>Most Sought After Employers</h3>
        <span>
          These are the most sought-after graduate employers, based on more than
          4,500 votes by Australian university students. Source:
          https://gradaustralia.com.au/top-100-employers/sector/it-communications
        </span>
        {/**
         * //TODO: MAKE THE COMPANYCARD MORE PRETTY
         */}
        <div>
          <CompanyCard
            companyName="Google"
            ranking={1}
            comapanyDescription="Our mission is to organise the world’s information and make it universally accessible and useful."
          />
          <CompanyCard
            companyName="Apple"
            ranking={2}
            comapanyDescription="Apple products help employees work more simply and productively, solve problems creatively and collaborate with a shared purpose. And they’re all designed to work together beautifully. When people have the power to work the way they want, with the tools they love, they can do their best work and change the future of their business."
          />
          <CompanyCard
            companyName="Microsoft"
            ranking={3}
            comapanyDescription="Microsoft enables digital transformation for the era of an intelligent cloud and an intelligent edge. Its mission is to empower every person and every organization on the planet to achieve more."
          />
          <CompanyCard
            companyName="IBM"
            ranking={4}
            comapanyDescription="IBMers believe in progress—that the application of intelligence, reason and science can improve business, society and the human condition"
          />
          <CompanyCard
            companyName="Telstra"
            ranking={5}
            comapanyDescription="Welcome to a world dedicated to life at Telstra. Take a look around and uncover what it’s like to be part of a global telecommunications and technology business. Uncover the myriad of career pathways open to you. Learn more about the people behind the brand and follow our global footprint around the world. And, when you’re ready to take the first or next step in your career, find your dream job and together, we'll reimagine the future."
          />
          <CompanyCard
            companyName="Atlassian"
            ranking={6}
            comapanyDescription="Behind every great human achievement, there is a team. 

From medicine and space travel, to disaster response and pizza deliveries, our products help teams all over the planet advance humanity through the power of software.

Our mission is to help unleash the potential of every team."
          />
          <CompanyCard
            companyName="Optus"
            ranking={7}
            comapanyDescription="We're investing in our people, our communities, our networks, and a sustainable future.   Our focus is on creating a better more connected future for Australia."
          />
          <CompanyCard
            companyName="Uber"
            ranking={8}
            comapanyDescription="Good things happen when people can move, whether across town or towards their dreams. Opportunities appear, open up, become reality. What started as a way to tap a button to get a ride has led to billions of moments of human connection as people around the world go all kinds of places in all kinds of ways with the help of our technology."
          />
          <CompanyCard
            companyName="Cisco"
            ranking={9}
            comapanyDescription="
CISCO SYSTEMS INC. IS THE WORLDWIDE LEADER in networking for the Internet. The company was founded in 1984 by two computer scientists from Stanford University seeking an easier way to connect different types of computer systems."
          />
          <CompanyCard
            companyName="IMC"
            ranking={10}
            comapanyDescription="Cutting-edge technology is at the heart of all we do. We put it to work in the markets that define our world. Across offices in Amsterdam, Chicago and Sydney, technologists and traders team up to explore, design and develop the software, hardware, advanced networks and algorithms that drive our trading strategies."
          />
          <CompanyCard
            companyName="Facebook"
            ranking={11}
            comapanyDescription="Founded in 2004, Facebook’s mission is to give people the power to build community and bring the world closer together. People use our products to stay connected with friends and family, to discover what’s going on in the world, and to share and express what matters to them."
          />
        </div>
      </div>
    </div>
  );
};

export default EmployersContainer;
