import React from "react";

import "./CompanyCard.scss";

const CompanyCard = ({ ranking, companyName, comapanyDescription, logo }) => {
  return (
    <div className="company-card">
      <div className="header">
        {logo && <img src={logo} alt={`${companyName}`} />}
        <div className="ranking">
          <h4>{`${ranking}`}</h4>
        </div>
        <div className="body">
          <h4>{companyName}</h4>
        </div>
      </div>
      {comapanyDescription && (
        <div className="company-description">
          <blockquote>{comapanyDescription}</blockquote>
        </div>
      )}
    </div>
  );
};

export default CompanyCard;
